const userTypes = {
  FORGETPWD_START: 'FORGETPWD_START',
  FORGETPWD_END: 'FORGETPWD_END',
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  BOOKMARK_POST: 'BOOKMARK_POST',
  CHANGE_AVATAR_START: 'CHANGE_AVATAR_START',
  CHANGE_AVATAR_SUCCESS: 'CHANGE_AVATAR_SUCCESS',
  CHANGE_AVATAR_FAILURE: 'CHANGE_AVATAR_FAILURE',
  REMOVE_AVATAR_START: 'REMOVE_AVATAR_START',
  REMOVE_AVATAR_SUCCESS: 'REMOVE_AVATAR_SUCCESS',
  REMOVE_AVATAR_FAILURE: 'REMOVE_AVATAR_FAILURE',
  UPDATE_PROFILE_START: 'UPDATE_PROFILE_START',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
};

export default userTypes;
