import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontSize: 20, // default

    h1: {
      fontSize: 48, // 3rem
    },
    h2: {
      fontSize: 32, // 2rem
    },
    h3: {
      fontSize: 24, // 1.5rem
    },
    h4: {
      fontSize: 22, // 1.4rem
    },
    h5: {
      fontSize: 20, // 1.2rem
    },
    h6: {
      fontSize: 18, // 1.1rem
    },
    body1: {
      fontSize: 16, // 1rem
    },
    body2: {
      fontSize: 14, // 1rem
    },
    button: {
      fontSize: 14, // 0.875rem
    },
    caption: {
      fontSize: 10,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px', 
        },
      },
    }
  },
});

export default theme;
