import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Box,
  Container,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
  Stack,
  Grid2,
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  Alert,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { MuiCard } from "../Card/MuiCard";
import { selectError, selectFetching } from "../../redux/user/userSelectors";
import { forgetPwdStart, getCaptchaError } from "../../redux/user/userActions";
import {
  reCaptcha,
  verifyCaptcha,
  forgotPassword,
} from "../../services/authenticationServices";
import { passwordRules } from "../../utils/validation";

const ForgetPwdCard = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const resetPasswordValidationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, "Password must be 6 characters long")
      .matches(
        /[0-9]/ || /[^\w]/,
        "Password requires a number or special character"
      )
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .required(),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleEmailSubmit = async (values) => {
    setResponseError(null);
    try {
      const response = await reCaptcha(values.email);
      if (response.status === 200) {
        setEmail(values.email);
        setStep(2);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  const handleVerifyCaptcha = async (values) => {
    setResponseError(null);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate a delay of 2 seconds
      const response = await verifyCaptcha(email, values.captcha);
      if (response.status === 200) {
        setStep(3);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  const handleResetPasswordSubmiit = async (values) => {
    setResponseError(null);
    try {
      const response = await forgotPassword(email, values.newPassword);
      if (response.status === 200) {
        setStep(4);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  return (
    <Container
      direction="column"
      justifyContent="space-between"
      sx={{ width: "80vh", padding: 4 }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          height: "100%",
          p: 2,
        }}
      >
        <MuiCard variant="outlined">
          {step === 1 && (
            <Fragment>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 500,
                  marginBottom: 2,
                  textAlign: "center",
                }}
              >
                Forgot your password?
              </Typography>
              <Typography variant="body1">
                Don't worry! It happens. Please enter the email address
                associated with your account.
              </Typography>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Please enter a valid email")
                    .required("Email is required"),
                })}
                onSubmit={handleEmailSubmit}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ display: "flex", flexDirection: "column", gap: 6 }}
                  >
                    <FormControl fullWidth size="small" required>
                      <TextField
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                          setResponseError(null);
                        }}
                        value={values.email}
                        error={errors.email ? true : false}
                        color={errors.email ? "error" : "primary"}
                        helperText={errors.email}
                      />
                      {responseError && (
                        <FormHelperText error>{responseError}</FormHelperText>
                      )}
                    </FormControl>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Continue
                    </LoadingButton>
                  </Box>
                )}
              </Formik>
            </Fragment>
          )}
          {step === 2 && (
            <Fragment>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 500,
                  marginBottom: 2,
                  textAlign: "center",
                }}
              >
                Enter Verification Code
              </Typography>
              <Typography variant="body1">
                An 6 character verification code has been sent to
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 550,
                }}
              >
                {email}
              </Typography>
              <Formik
                initialValues={{ captcha: "" }}
                validationSchema={Yup.object().shape({
                  captcha: Yup.string()
                    .length(
                      6,
                      "Verification code must be exactly 6 characters long"
                    )
                    .required("Verification code is required"),
                })}
                onSubmit={handleVerifyCaptcha}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => {
                  return (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 6,
                      }}
                    >
                      <FormControl fullWidth size="small" required>
                        <TextField
                          id="captcha"
                          name="captcha"
                          placeholder="Enter verification code"
                          autoComplete="captcha"
                          variant="outlined"
                          onChange={(e) => {
                            handleChange(e);
                            setResponseError(null);
                          }}
                          value={values.captcha}
                          helperText={errors.captcha}
                          error={errors.captcha ? true : false}
                          color={errors.captcha ? "error" : "primary"}
                        />
                        {responseError && (
                          <FormHelperText error sx={{ fontSize: "16px" }}>
                            {responseError}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Verify
                      </LoadingButton>
                      <Link href="/resend_email" sx={{ textAlign: "center" }}>
                        Didn't receive an email? Resend email
                      </Link>
                    </Box>
                  );
                }}
              </Formik>
            </Fragment>
          )}
          {step === 3 && (
            <Fragment>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 500,
                  marginBottom: 2,
                  textAlign: "center",
                }}
              >
                Reset Password
              </Typography>
              {responseError && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {responseError}
                </Alert>
              )}
              <Formik
                initialValues={{
                  newPassword: "",
                  passwordConfirm: "",
                }}
                validationSchema={resetPasswordValidationSchema}
                onSubmit={handleResetPasswordSubmiit}
              >
                {({
                  values,
                  isSubmitting,
                  isValid,
                  dirty,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      error={
                        touched.newPassword && errors.newPassword ? true : false
                      }
                      color={
                        !touched.newPassword
                          ? "primary"
                          : errors.newPassword
                          ? "error"
                          : "primary"
                      }
                    >
                      <InputLabel htmlFor="password">New Password</InputLabel>
                      <OutlinedInput
                        id="newPassword"
                        name="newPassword"
                        label="newPassword"
                        type={showPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((show) => !show)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Typography mt={1}>
                        Your password must contain at least
                      </Typography>
                      <List dense sx={{ paddingBottom: 0 }}>
                        {passwordRules.map((rule, index) => (
                          <ListItem key={index} sx={{ paddingY: "2px" }}>
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              {rule.test(values.newPassword) ? (
                                <CheckCircleIcon
                                  fontSize="10px"
                                  color="success"
                                />
                              ) : (
                                <RadioButtonUncheckedIcon fontSize="10px" />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                color: rule.test(values.newPassword)
                                  ? "green"
                                  : "red",
                                my: 0,
                              }}
                            >
                              {rule.label}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      error={
                        touched.passwordConfirm &&
                        errors.passwordConfirm &&
                        values.newPassword !== ""
                          ? true
                          : false
                      }
                      color={
                        !touched.passwordConfirm
                          ? "primary"
                          : errors.passwordConfirm && values.newPassword !== ""
                          ? "error"
                          : "primary"
                      }
                    >
                      <InputLabel htmlFor="passwordConfirm">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="passwordConfirm"
                        name="passwordConfirm"
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.passwordConfirm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((show) => !show)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.passwordConfirm &&
                        errors.passwordConfirm &&
                        values.newPassword !== "" && (
                          <FormHelperText error>
                            {errors.passwordConfirm}
                          </FormHelperText>
                        )}
                    </FormControl>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isSubmitting}
                      sx={{ marginTop: 3 }}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                )}
              </Formik>
            </Fragment>
          )}
          {step === 4 && (
            <Grid2
              container
              spacing={{ xs: 8, sm: 12 }}
              direction="column"
              justifyContent="center"
            >
              <Grid2 container justifyContent="center" spacing={0}>
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ fontSize: { xs: 80, sm: 120 } }}
                />
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    width: "100%",
                    fontWeight: 550,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Your password has been updated successfully
                </Typography>
              </Grid2>
              <Grid2 container justifyContent="center">
                <Button href="/login" variant="contained">
                  Login to continue
                </Button>
              </Grid2>
            </Grid2>
          )}
        </MuiCard>
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  forgetPwdStart: (email, password, captcha) =>
    dispatch(forgetPwdStart(email, password, captcha)),
  getCaptchaError: (err) => dispatch(getCaptchaError(err)),
});

const mapStateToProps = createStructuredSelector({
  error: selectError,
  fetching: selectFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPwdCard);
