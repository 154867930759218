import React, { Fragment, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Box,
  Container,
  Button,
  RadioGroup,
  Radio,
  Divider,
  FormControlLabel,
  FormLabel,
  FormControl,
  Link,
  TextField,
  Typography,
  Stack,
  Grid2,
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  Alert,
  AlertTitle,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { GoogleIcon, FacebookIcon } from "../Icon/CustomIcons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { MuiCard } from "../Card/MuiCard";
import { selectError, selectFetching } from "../../redux/user/userSelectors";
import {
  signUpStart,
  getCaptchaError,
} from "../../redux/user/userActions";
import {
  sendCaptcha,
  verifyCaptcha,
  registerUser,
} from "../../services/authenticationServices";
import { passwordRules } from "../../utils/validation";

const SignUpCard = () => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const signUpValidationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required"),
    userType: Yup.string().required(),
    password: Yup.string()
      .min(6, "Password must be 6 characters long")
      .matches(
        /[0-9]/ || /[^\w]/,
        "Password requires a number or special character"
      )
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .required(),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const handleEmailSubmit = async (values) => {
    setResponseError(null);
    try {
      const response = await sendCaptcha(values.email);
      if (response.status === 200) {
        setEmail(values.email);
        setStep(2);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  const handleVerifyCaptcha = async (values) => {
    setResponseError(null);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate a delay of 2 seconds
      const response = await verifyCaptcha(email, values.captcha);
      if (response.status === 200) {
        setStep(3);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  const handleSignUpSubmiit = async (values) => {
    setResponseError(null);
    try {
      const response = await registerUser(
        email,
        values.firstName,
        values.lastName,
        values.username,
        values.password,
        values.userType
      );
      if (response.status === 200) {
        setStep(4);
      }
    } catch (err) {
      setResponseError(err.message);
    }
  };

  return (
    <Container
      direction="column"
      justifyContent="space-between"
      sx={{ width: "80vh", padding: 4 }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          height: "100%",
          p: 2,
        }}
      >
        <MuiCard variant="outlined">
          <Typography
            component={isSmallScreen ? "h2" : "h1"}
            variant={isSmallScreen ? "h2" : "h1"}
            sx={{
              width: "100%",
              // fontSize: "clamp(3rem, 10vw, 3.5rem)",
              fontFamily: "'Leckerli One', sans-serif",
              textAlign: "center",
            }}
          >
            Team up Sport
          </Typography>
          {step === 1 && (
            <Fragment>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  width: "100%",
                  fontWeight: 550,
                  textAlign: "center",
                }}
              >
                Sign up to continue
              </Typography>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Please enter a valid email")
                    .required("Email is required"),
                })}
                onSubmit={handleEmailSubmit}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormControl fullWidth size="small" required>
                      <TextField
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                          setResponseError(null);
                        }}
                        value={values.email}
                        error={errors.email ? true : false}
                        color={errors.email ? "error" : "primary"}
                        helperText={errors.email}
                      />
                      {responseError && (
                        <FormHelperText error>{responseError}</FormHelperText>
                      )}
                    </FormControl>
                    <Typography variant="body2">
                      By signing up, I accept the Team Up Sport{" "}
                      <Link href="/terms_of_service">Terms of Service</Link> and
                      acknowledge the{" "}
                      <Link href="/privacy_policy">Privacy Policy</Link>.
                    </Typography>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Continue
                    </LoadingButton>
                    <Divider>
                      <Typography sx={{ color: "text.secondary" }}>
                        or
                      </Typography>
                    </Divider>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        onClick={() => alert("Sign up with Google")}
                        startIcon={<GoogleIcon />}
                      >
                        Sign up with Google
                      </Button>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        onClick={() => alert("Sign up with Facebook")}
                        startIcon={<FacebookIcon />}
                      >
                        Sign up with Facebook
                      </Button>
                    </Box>
                    <Typography sx={{ textAlign: "center" }}>
                      Already have an account?{" "}
                      <span>
                        <Link
                          href="/login"
                          variant="body2"
                          sx={{ alignSelf: "center" }}
                        >
                          Log in
                        </Link>
                      </span>
                    </Typography>
                  </Box>
                )}
              </Formik>
            </Fragment>
          )}
          {step === 2 && (
            <Fragment>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  width: "100%",
                  fontWeight: 550,
                  textAlign: "center",
                }}
              >
                We've emailed you a code
              </Typography>
              <Typography variant="body1">
                To complete your account setup, enter the code we've sent to:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 550,
                }}
              >
                {email}
              </Typography>
              <Formik
                initialValues={{ captcha: "" }}
                validationSchema={Yup.object().shape({
                  captcha: Yup.string()
                    .length(
                      6,
                      "Verification code must be exactly 6 characters long"
                    )
                    .required("Verification code is required"),
                })}
                onSubmit={handleVerifyCaptcha}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                }) => {
                  return (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <FormControl fullWidth size="small" required>
                        <TextField
                          id="captcha"
                          name="captcha"
                          placeholder="Enter verification code"
                          autoComplete="captcha"
                          variant="outlined"
                          onChange={(e) => {
                            handleChange(e);
                            setResponseError(null);
                          }}
                          value={values.captcha}
                          helperText={errors.captcha}
                          error={errors.captcha ? true : false}
                          color={errors.captcha ? "error" : "primary"}
                        />
                        {responseError && (
                          <FormHelperText error sx={{ fontSize: "16px" }}>
                            {responseError}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Verify
                      </LoadingButton>
                      <Link href="/resend_email" sx={{ textAlign: "center" }}>
                        Didn't receive an email? Resend email
                      </Link>
                    </Box>
                  );
                }}
              </Formik>
            </Fragment>
          )}
          {step === 3 && (
            <Fragment>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  width: "100%",
                  fontWeight: 550,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Email address verified
                <CheckCircleIcon sx={{ marginLeft: 1 }} color="success" />
              </Typography>
              {responseError && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {responseError}
                </Alert>
              )}
              <Typography
                variant="body2"
                sx={{
                  width: "100%",
                  fontWeight: 550,
                }}
              >
                To finish signing up, enter your account details
              </Typography>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  username: "",
                  password: "",
                  passwordConfirm: "",
                  userType: "REGULAR",
                }}
                validationSchema={signUpValidationSchema}
                onSubmit={handleSignUpSubmiit}
              >
                {({
                  values,
                  isSubmitting,
                  isValid,
                  dirty,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Grid2
                      container
                      // direction={{ xs: "column", lg: "row" }}
                      direction="row"
                      spacing={2}
                    >
                      <Grid2 sx={{ flexGrow: 1 }} size={6}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          error={
                            touched.firstName && errors.firstName ? true : false
                          }
                          color={
                            !touched.firstName
                              ? "primary"
                              : errors.firstName
                              ? "error"
                              : "primary"
                          }
                        >
                          <InputLabel htmlFor="firstName">
                            First name
                          </InputLabel>
                          <OutlinedInput
                            required
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                          {touched.firstName && errors.firstName && (
                            <FormHelperText error>
                              {errors.firstName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid2>
                      <Grid2 sx={{ flexGrow: 1 }} size={6}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          error={
                            touched.lastName && errors.lastName ? true : false
                          }
                          color={
                            !touched.lastName
                              ? "primary"
                              : errors.lastName
                              ? "error"
                              : "primary"
                          }
                        >
                          <InputLabel htmlFor="lastName">Last name</InputLabel>
                          <OutlinedInput
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                          {touched.lastName && errors.lastName && (
                            <FormHelperText error>
                              {errors.lastName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid2>
                    </Grid2>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      error={touched.username && errors.username ? true : false}
                      color={
                        !touched.username
                          ? "primary"
                          : errors.username
                          ? "error"
                          : "primary"
                      }
                    >
                      <InputLabel htmlFor="username">Username</InputLabel>
                      <OutlinedInput
                        id="username"
                        name="username"
                        label="Username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.username}
                      />
                      {touched.username && errors.username && (
                        <FormHelperText error>{errors.username}</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      error={touched.password && errors.password ? true : false}
                      color={
                        !touched.password
                          ? "primary"
                          : errors.password
                          ? "error"
                          : "primary"
                      }
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((show) => !show)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Typography mt={1}>
                        Your password must contain at least
                      </Typography>
                      <List dense sx={{ paddingBottom: 0 }}>
                        {passwordRules.map((rule, index) => (
                          <ListItem key={index} sx={{ paddingY: "2px" }}>
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              {rule.test(values.password) ? (
                                <CheckCircleIcon
                                  fontSize="10px"
                                  color="success"
                                />
                              ) : (
                                <RadioButtonUncheckedIcon fontSize="10px" />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                color: rule.test(values.password)
                                  ? "green"
                                  : "red",
                                my: 0,
                              }}
                            >
                              {rule.label}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      size="small"
                      required
                      error={
                        touched.passwordConfirm &&
                        errors.passwordConfirm &&
                        values.password !== ""
                          ? true
                          : false
                      }
                      color={
                        !touched.passwordConfirm
                          ? "primary"
                          : errors.passwordConfirm && values.password !== ""
                          ? "error"
                          : "primary"
                      }
                    >
                      <InputLabel htmlFor="passwordConfirm">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="passwordConfirm"
                        name="passwordConfirm"
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.passwordConfirm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((show) => !show)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.passwordConfirm &&
                        errors.passwordConfirm &&
                        values.password !== "" && (
                          <FormHelperText error>
                            {errors.passwordConfirm}
                          </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl required>
                      <FormLabel htmlFor="userType">User Type</FormLabel>
                      <RadioGroup
                        row
                        id="userType"
                        name="userType"
                        value={values.userType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="REGULAR"
                          control={<Radio />}
                          label="Regular User"
                        />
                        <FormControlLabel
                          value="ATHELETE"
                          control={<Radio />}
                          label="Athlete"
                        />
                      </RadioGroup>
                    </FormControl>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isSubmitting}
                    >
                      Sign Up
                    </LoadingButton>
                  </Box>
                )}
              </Formik>
            </Fragment>
          )}
          {step === 4 && (
            <Grid2
              container
              spacing={{ xs: 8, sm: 12 }}
              direction="column"
              justifyContent="center"
            >
              <Grid2 container justifyContent="center" spacing={0}>
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ fontSize: { xs: 80, sm: 120 } }}
                />
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    width: "100%",
                    fontWeight: 550,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  You're All Set
                </Typography>
              </Grid2>
              <Grid2 container justifyContent="center">
                <Button href="/login" variant="contained">
                  Login to continue
                </Button>
              </Grid2>
            </Grid2>
          )}
        </MuiCard>
      </Stack>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signUpStart: (email, fullName, username, password, captcha, userType) =>
    dispatch(
      signUpStart(email, fullName, username, password, captcha, userType)
    ),
  getCaptchaError: (err) => dispatch(getCaptchaError(err)),
});

const mapStateToProps = createStructuredSelector({
  error: selectError,
  fetching: selectFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCard);
