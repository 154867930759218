import axios from "axios";

/**
 * Logs a user in with the provided credentials
 * @function login
 * @param {string} usernameOrEmail The username or email to login with
 * @param {string} password A password to log in with
 * @param {string} authToken A token to be used instead of a username/email or password
 * @returns {object} The user object
 */
export const login = async (usernameOrEmail, password, authToken) => {
  try {
    const request =
      usernameOrEmail && password
        ? { data: { usernameOrEmail, password } }
        : {
            data: { usernameOrEmail, password },
            headers: { authorization: authToken },
          };
    const response = await axios("/api/auth/login", {
      method: "POST",
      ...request,
    });
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

/**
 * Registers a user with the provided credentials
 * @param {string} email A user's email address
 * @param {string} firstName A user's first name
 * * @param {string} lastName A user's last name
 * @param {string} username A user's username
 * @param {string} password A user's password
 * @param {string} userType A user's user type
 * @returns {object} The user object
 */
export const registerUser = async (
  email,
  firstName,
  lastName,
  username,
  password,
  userType
) => {
  try {
    const response = await axios.post("/api/auth/register", {
      email,
      firstName,
      lastName,
      username,
      password,
      userType,
    });
    return response;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

export const forgotPassword = async (email, newPassword) => {
  try {
    const response = await axios.patch("/api/auth/forgot-password", {
      email,
      newPassword,
    });
    return response;
  } catch (err) {
    throw new Error(err.response.data.error || "Failed to reset password");
  }
};

/**
 * Gets a captcha when first register
 * @function getCaptcha
 * @param {string} email The user's current email
 */

export const getCaptcha = async (email) => {
  try {
    const response = await axios.get("/api/auth/captcha", {
      params: {
        email,
      },
    });
  } catch (err) {
    throw new Error(err.response.data.error || "Failed to retrieve captcha");
  }
};

export const sendCaptcha = async (email) => {
  try {
    const response = await axios.get("/api/auth/captcha", {
      params: {
        email,
      },
    });
    return response;
  } catch (err) {
    throw new Error(
      err.response
        ? err.response.data.error
        : "Failed to send verification code"
    );
  }
};

export const reCaptcha = async (email) => {
  try {
    const response = await axios.get("/api/auth/reCaptcha", {
      params: {
        email,
      },
    });
    return response;
  } catch (err) {
    throw new Error(
      err.response
        ? err.response.data.error
        : "Failed to send verification code"
    );
  }
};

export const verifyCaptcha = async (email, captcha) => {
  try {
    const response = await axios.post("/api/auth/verifyCaptcha", {
      email,
      captcha,
    });
    return response;
  } catch (err) {
    throw new Error(
      err.response ? err.response.data.error : "Failed to verify email"
    );
  }
};

/**
 * Changes a users password
 * @function changePassword
 * @param {string} oldPassword The user's current password
 * @param {string} newPassword The new password
 * @param {string} authToken A user's auth token
 */
export const changePassword = async (oldPassword, newPassword, authToken) => {
  try {
    await axios.put(
      "/api/auth/password",
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          authorization: authToken,
        },
      }
    );
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};
