import React from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

const FormTextarea = ({
  children,
  rows,
  textLimit,
  fieldProps,
  ...additionalProps
}) => (
  <div>
    <textarea
      className="form-group__textarea"
      rows={rows}
      {...fieldProps}
      {...additionalProps}
    >
      {children}
    </textarea>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "12px",
        color: grey[600],
      }}
    >
      {textLimit && (
        <p>
          {fieldProps.value.length} / {textLimit}
        </p>
      )}
    </Box>
  </div>
);

export default FormTextarea;
