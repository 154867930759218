const profileTypes = {
  FETCH_PROFILE_START: 'FETCH_PROFILE_START',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_ADDITIONAL_POSTS_START: 'FETCH_ADDITIONAL_POSTS_START',
  FETCH_ADDITIONAL_POSTS_FAILURE: 'FETCH_ADDITIONAL_POSTS_FAILURE',
  FETCH_ADDITIONAL_POSTS_SUCCESS: 'FETCH_ADDITIONAL_POSTS_SUCCESS',
  SET_POST_VOTES_COUNT: 'SET_POST_VOTES_COUNT',
  INCREMENT_POST_COMMENTS_COUNT: 'INCREMENT_POST_COMMENTS_COUNT',
  DECREMENT_POST_COMMENTS_COUNT: 'DECREMENT_POST_COMMENTS_COUNT',
  ADD_POSTS: 'ADD_POSTS',
  DELETE_POST: 'DELETE_POST',
  FOLLOW_USER_START: 'FOLLOW_USER_START',
  FOLLOW_USER_FAILURE: 'FOLLOW_USER_FAILURE',
  FOLLOW_USER_SUCCESS: 'FOLLOW_USER_SUCCESS',
};

export default profileTypes;