import React from 'react';

import Avatar from "@mui/material/Avatar";

const UnfollowPrompt = ({ avatar, username }) => (
  <div className="unfollow-prompt">
    <Avatar sx={{ width: '10rem', height: '10rem' }} src={avatar} />
    <p
      style={{ marginTop: '3rem' }}
      className="heading-4"
    >{`Unfollow @${username}?`}</p>
  </div>
);

export default UnfollowPrompt;
