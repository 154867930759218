import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CommentIcon from '@mui/icons-material/Comment';
import ArchiveIcon from '@mui/icons-material/Archive';
import TranslateIcon from '@mui/icons-material/Translate';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Grid2 } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function DrawerLeft({ children }) {
  const pathname = useLocation().pathname;
  const drawerWidth = 240;

  const drawerItems = [
    { text: 'Profile', to: '/settings/profile', icon: <AccountCircleIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Notifications', to: '/settings/notifications', icon: <CircleNotificationsIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Tags and mentions', to: '/settings/tags_and_mentions', icon: <AlternateEmailIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Comment', to: '/settings/comments', icon: <CommentIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Archive', to: '/settings/archive', icon: <ArchiveIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Language', to: '/settings/languages', icon: <TranslateIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Subscriptions', to: '/settings/subscriptions', icon: <SubscriptionsIcon sx={{ fontSize: "2.5rem" }} /> },
    { text: 'Account', to: '/settings/account', icon: <ManageAccountsIcon sx={{ fontSize: "2.5rem" }} /> },
  ]

  return (
    <Grid2 container>
      <Grid2 size={3}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              top: "7.5rem", // header height
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Divider />
          <List>
            {drawerItems.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ paddingY: "4px", paddingX: 1}}>
                <ListItemButton component={NavLink} to={item.to} sx={{ backgroundColor: item.to === pathname ? grey[200] : null, borderRadius:"10px" }}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Grid2>
      <Grid2 size={9} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ maxWidth: "70%", flexGrow: 1 }}>
          {children}
        </Box>
      </Grid2>
    </Grid2>
  );
}
