import profileTypes from "./profileTypes";

export const INITIAL_STATE = {
  fetching: true,
  following: false,
  fetchingAdditionalPosts: false,
  error: false,
  data: {
    posts: [],
    isFollowing: false,
    followersCount: 0,
    followingCount:0,
  },
};

export const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case profileTypes.FETCH_PROFILE_START: {
      return { ...state, fetching: true, error: false };
    }
    case profileTypes.FETCH_PROFILE_FAILURE: {
      return { ...state, fetching: false, error: action.payload };
    }
    case profileTypes.FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: {
          ...action.payload,
          posts: action.payload.posts ? action.payload.posts.data : [],
          postCount: action.payload.posts ? action.payload.posts.postCount : 0,
        },
      };
    }
    case profileTypes.FOLLOW_USER_START: {
      return { ...state, following: true };
    }
    case profileTypes.FOLLOW_USER_FAILURE: {
      return { ...state, following: false, error: action.payload };
    }
    case profileTypes.FOLLOW_USER_SUCCESS: {
      return {
        ...state,
        following: false,
        data: {
          ...state.data,
          isFollowing: !state.data.isFollowing, // Toggle the following state
          followersCount: state.data.isFollowing
            ? Math.max(0, state.data.followersCount - 1) // Decrement if previously following
            : state.data.followersCount + 1, // Increment if just followed
        },
      };
    }
    default: {
      return state
    }
  }
};

export default profileReducer;
